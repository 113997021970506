import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import {graphql} from "gatsby"
import {HelmetDatoCms} from "gatsby-source-datocms"

export default class Publication extends React.Component {
    render() {
        const p = this.props, { } = p
        const publication = p.data.publication
        const page = p.data.page.edges.filter(({node: item}) => item.name === "Publikacje")

        return (
            <Layout>
                <HelmetDatoCms seo={page[0].node.seoMetaTags} />
                <div className="main-image">
                    <img src="../publikacje.jpg" alt="" />
                </div>
                <div className="content">
                    {page[0].node.header &&
                        <div className="page-title">
                            <h3>{page[0].node.header}</h3>
                        </div>
                    }
                    <div className="page-back">
                        <a href={`/${page[0].node.slug}`}><i className="icon-arrow-left"></i> Powrót</a>
                    </div>
                    <div className="publications">
                        <div className="item item--simple">
                            {publication.image && <Img fluid={publication.image.fluid} />}
                            {!publication.image && <img src="../no-publication.jpg" className="no-preview" alt=""/>}
                            <div className="item__content">
                                <p><strong>{publication.title}</strong></p>
                                <pre dangerouslySetInnerHTML={{
                                    __html: publication.descriptionNode.childMarkdownRemark.html,
                                }}/>
                                <div className="item__actions">
                                    <a href={publication.file.url} className="btn-yellow btn-yellow--sm" title="Pobierz" target="_blank">
                                        <span>Pobierz</span>
                                        <i className="icon-download"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export const query = graphql`
  query PublicationQuery($slug: String!) {
      publication: datoCmsPublikacje(slug: { eq: $slug }) {
        image {
            fluid(imgixParams: { auto: "compress" }) {
                ...GatsbyDatoCmsSizes
            }
        }
        title
        descriptionNode {
            childMarkdownRemark{
                html
            }
        }
        slug
        file {
            url
        }
      },
      page: allDatoCmsCustomPage {
          edges {
              node {
                seoMetaTags {
                    ...GatsbyDatoCmsSeoMetaTags
                }
                name
                slug
                header
              }
          }
      }
  }
`